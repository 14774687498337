import { createSelector } from 'reselect';

// Design System
import stripWhitespace from 'jl-design-system/utils/string/stripWhitespace';
import env from 'jl-design-system/utils/env/env';
import { getFormInitialValuesFromAddress } from 'jl-design-system/utils/address/address';
import { phoneNumberUkMobileRegex } from 'jl-design-system/form/regex';
import transformUkPhoneNumber from 'jl-design-system/form/normalizers/transformUkPhoneNumber';
import { TITLE_OPTIONS } from 'jl-design-system/form/fields/title';

// Config
import mergeBillingAddressSources from './mergeBillingAddressSources';
import { isFormMounted } from '..';
import appConstants from '@constants/appConstants';
import featureConstants from '@constants/featureConstants';
import paymentTypeConstants from '@constants/paymentTypeConstants';
import { APPLE_PAY_BILLING_ADDRESS_FORM_ID } from '@utils/form/configs/applePayBillingAddress';
import { BILLING_ADDRESS_FORM_ID, RESIDENTIAL_ADDRESS_FORM_ID } from '@utils/form/configs/billingAddress';
import { GOOGLE_PAY_BILLING_ADDRESS_FORM_ID } from '@utils/form/configs/googlePayBillingAddress';
import {
  PAYPAL_BILLING_ADDRESS_FORM_ID,
  PAYPAL_SHIPPING_ADDRESS_FORM_ID,
} from '@utils/form/configs/payPalAddressDetails';
import { getStorageItem } from '@utils/storage/storage';
import { deepEqual } from '@utils/object';

export function shouldMockLoqate() {
  return getStorageItem(featureConstants.USE_REAL_LOQATE_OVERRIDE, true) === 'true'
    ? false
    : env.isClientLocal || env.isClientDev;
}

export function getAddressFromFormValues(formValues = {}) {
  const {
    title,
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    townOrCity,
    countyStateOrProvince,
    postcode,
    countryCode,
    phoneNumber,
    id,
  } = formValues;

  // TODO conditionally add fields to objects to avoid creating object full of undefined values
  return {
    addressee: {
      // small hack to conditionally set title as field is not required/used in invalid paypal billing form
      ...(title && { title }),
      firstName,
      lastName,
    },
    address: {
      addressLine1,
      addressLine2,
      ...Object.keys({
        addressLine3,
        addressLine4,
      }).reduce((acc, key) => (formValues[key] ? { ...acc, [key]: formValues[key] } : acc), {}),
      townOrCity,
      countyStateOrProvince,
      postcode,
      countryCode,
    },
    phoneNumber: stripWhitespace(transformUkPhoneNumber(phoneNumber)),
    ...(id && { id }),
  };
}

export function parseAddressPhoneNumber(addressObject) {
  if (addressObject) {
    const { phoneNumber } = addressObject;
    return {
      ...addressObject,
      ...(phoneNumber ? { phoneNumber: stripWhitespace(transformUkPhoneNumber(phoneNumber)) } : {}),
    };
  }

  return addressObject;
}

export function getAddressBookAddressPayloadFromFormValues(formValues = {}) {
  const { contact } = formValues;

  const payload = {
    ...getAddressFromFormValues(formValues),
    contact: contact || false,
  };

  return payload;
}

export function getBillingAddressForPaymentType({
  addressBook,
  paymentType = '',
  savedPaymentCards,
  shouldSetBillingAddress = true,
}) {
  if (!shouldSetBillingAddress) {
    return null;
  }

  if (paymentType?.includes(paymentTypeConstants.SAVED_PAYMENT_CARD)) {
    const selectedSavedCardId = paymentType.split(':')[1];
    const selectedCard = savedPaymentCards?.find((paymentCard) => paymentCard.id === selectedSavedCardId);
    const paymentCardAddressInAddressBook = addressBook?.find(
      (addressRecord) => addressRecord.id === selectedCard.addressId,
    );

    if (paymentCardAddressInAddressBook) {
      // MARV-8816 only return the saved payment card's address if it can be found in address book
      return paymentCardAddressInAddressBook;
    }
  }

  const contactAddress = addressBook?.find(
    (addressRecord) => addressRecord.contact && addressRecord.address?.countryCode === 'GB',
  );
  if (contactAddress) {
    return contactAddress;
  }

  return addressBook[0];
}

export const getBillingAddress = createSelector(
  (state) => state,
  (state) => {
    const isPosCreditPayment = state?.payment?.selectedPaymentType?.includes(paymentTypeConstants.POS_CREDIT) || false;

    if (isPosCreditPayment) {
      if (isFormMounted(state.form?.[RESIDENTIAL_ADDRESS_FORM_ID])) {
        const billingAddressFormValues = state.form?.[RESIDENTIAL_ADDRESS_FORM_ID]?.values;
        return getAddressFromFormValues(billingAddressFormValues);
      }

      return parseAddressPhoneNumber(state?.user?.selectedResidentialAddress);
    }

    if (isFormMounted(state.form?.[PAYPAL_BILLING_ADDRESS_FORM_ID])) {
      const billingAddressFormValues = state.form?.[PAYPAL_BILLING_ADDRESS_FORM_ID]?.values;
      return getAddressFromFormValues(billingAddressFormValues);
    }

    if (isFormMounted(state.form?.[GOOGLE_PAY_BILLING_ADDRESS_FORM_ID])) {
      const billingAddressFormValues = state.form?.[GOOGLE_PAY_BILLING_ADDRESS_FORM_ID]?.values;
      return getAddressFromFormValues(billingAddressFormValues);
    }

    if (isFormMounted(state.form?.[APPLE_PAY_BILLING_ADDRESS_FORM_ID])) {
      const billingAddressFormValues = state.form?.[APPLE_PAY_BILLING_ADDRESS_FORM_ID]?.values;
      return getAddressFromFormValues(billingAddressFormValues);
    }

    if (isFormMounted(state.form?.[BILLING_ADDRESS_FORM_ID])) {
      const mergedValues = mergeBillingAddressSources(state, true);
      return getAddressFromFormValues(mergedValues);
    }

    if (state?.user?.previewSelectedBillingAddress) {
      const mergedValues = mergeBillingAddressSources(state);
      return getAddressFromFormValues(mergedValues);
    }

    return parseAddressPhoneNumber(state?.user?.selectedBillingAddress || state?.delivery?.confirmedDeliveryAddress);
  },
);

export function getShippingAddress(state = {}) {
  if (isFormMounted(state.form?.[PAYPAL_SHIPPING_ADDRESS_FORM_ID])) {
    const addressFormValues = state.form?.[PAYPAL_SHIPPING_ADDRESS_FORM_ID]?.values;
    return getAddressFromFormValues(addressFormValues);
  }

  return undefined;
}

export function checkAddressFormValuesEqualToAddressObject(formValues, address) {
  const parsedFormValues = Object.keys(formValues).reduce(
    (acc, key) => (formValues[key] ? { ...acc, [key]: formValues[key] } : acc),
    {},
  );

  const addressValues = getFormInitialValuesFromAddress(address);
  const parsedAddressValues = Object.keys(addressValues).reduce(
    (acc, key) => (addressValues[key] ? { ...acc, [key]: addressValues[key] } : acc),
    {},
  );

  return deepEqual(parsedFormValues, parsedAddressValues);
}

export function getPhoneNumberDropdownOptions() {
  const countries = JSON.parse(getStorageItem(appConstants.COUNTRIES));

  const validCountries = Array.isArray(countries) ? countries : [];
  const formattedCountries = validCountries.map((country) => ({
    callingCode: country.callingCode,
    label: `${country.label} (${country.callingCode})`,
    selectedLabel: `(${country.callingCode})`,
    placeholder: country.callingCodePlaceholder,
    value: country.value,
  }));

  // move GB to top of the list
  const gbIndex = formattedCountries.findIndex((country) => country.value === 'GB');
  formattedCountries.splice(0, 0, formattedCountries[gbIndex]);
  formattedCountries.splice(gbIndex + 1, 1);

  return formattedCountries;
}

export function checkIsUkMobileNumber(number) {
  const regex = phoneNumberUkMobileRegex;
  return regex.test(number);
}

export function countryCodeIsInternational(countryCode) {
  const britishCountryCodes = ['GB', 'GG', 'IM', 'JE'];
  return !britishCountryCodes.some((code) => code.includes(countryCode));
}

export function getFormattedAddressFromApplePay(applePayAddress, phoneNumber) {
  const addressLines = applePayAddress?.addressLines ?? [];
  const { administrativeArea, countryCode, familyName, givenName, locality, postalCode } = applePayAddress;

  return {
    addressee: {
      firstName: givenName,
      lastName: familyName,
    },
    address: {
      ...addressLines.reduce((acc, item, index) => {
        acc[`addressLine${index + 1}`] = item;
        return acc;
      }, {}),
      townOrCity: locality,
      countyStateOrProvince: administrativeArea,
      postcode: postalCode,
      countryCode,
    },
    phoneNumber: stripWhitespace(transformUkPhoneNumber(phoneNumber)),
  };
}

export function mapAddressValidationErrorToApplePayErrors({
  validationErrors = {},
  errorKey = 'billingContactInvalid',
}) {
  const mappedErrors = Object.keys(validationErrors).map((error) => {
    switch (error) {
      case 'firstName':
      case 'lastName': {
        return {
          field: 'name',
          readableFieldName: 'Name',
        };
      }

      case 'addressLine1':
      case 'addressLine2':
      case 'addressLine3':
      case 'addressLine4': {
        return {
          field: 'addressLines',
          readableFieldName: 'Address line(s)',
        };
      }

      case 'townOrCity':
        return {
          field: 'locality',
          readableFieldName: 'Town/City',
        };

      case 'countyStateOrProvince':
        return {
          field: 'administrativeArea',
          readableFieldName: 'County/State',
        };

      case 'postcode':
        return {
          field: 'postalCode',
          readableFieldName: 'Post code',
        };

      case 'phoneNumber': {
        return {
          field: 'phoneNumber',
          key: 'shippingContactInvalid',
          readableFieldName: 'Phone number',
        };
      }
      default:
        return undefined;
    }
  });

  let errorMessage;
  if (Object.keys(validationErrors).length === 1) {
    errorMessage = `There is an issue with your ${mappedErrors[0]?.readableFieldName}, please update it to continue.`;
  } else {
    errorMessage = 'There are issues with your address, please update them to continue.';
  }

  return {
    paymentMethod: mappedErrors.map(
      (error) => new window.ApplePayError(error?.key || errorKey, error?.field, errorMessage),
    ),
  };
}

export function mapFullName(rawName) {
  const splitName = rawName.split(' ');

  // if first element matches a title field, separate from the rest of the name
  const titles = TITLE_OPTIONS.map((item) => item.value);
  const titleExists = titles.includes(splitName[0]);
  const name = titleExists ? splitName.slice(1) : splitName;

  if (name.length === 2) {
    return {
      ...(titleExists ? { title: splitName[0] } : {}),
      firstName: name[0],
      lastName: name[1],
    };
  }

  if (name.length > 2) {
    return {
      ...(titleExists ? { title: splitName[0] } : {}),
      firstName: name.slice(0, -1).join(' '),
      lastName: name[name.length - 1],
    };
  }

  return {
    ...(titleExists ? { title: splitName[0] } : {}),
    firstName: name[0],
    lastName: '',
  };
}

export function getFormattedAddressFromGooglePay(googlePayAddress) {
  const { name, address1, address2, address3, locality, administrativeArea, postalCode, countryCode, phoneNumber } =
    googlePayAddress;

  return {
    addressee: {
      ...mapFullName(name),
    },
    address: {
      addressLine1: address1,
      addressLine2: address2,
      addressLine3: address3,
      countyStateOrProvince: administrativeArea,
      townOrCity: locality,
      postcode: postalCode,
      countryCode,
    },
    phoneNumber: stripWhitespace(transformUkPhoneNumber(phoneNumber)),
  };
}

export function getFormattedAddressFromStripe(stripeAddress) {
  if (!stripeAddress) return undefined;

  const { name, address = {}, phone } = stripeAddress;
  const { city, country, line1, line2, line3, postal_code, state } = address;

  return {
    addressee: {
      ...mapFullName(name),
    },
    address: {
      addressLine1: line1 ?? '',
      addressLine2: line2 ?? '',
      addressLine3: line3 ?? '',
      countyStateOrProvince: state ?? '',
      townOrCity: city ?? '',
      postcode: postal_code ?? '',
      countryCode: country ?? '',
    },
    phoneNumber: stripWhitespace(transformUkPhoneNumber(phone ?? '')),
  };
}
